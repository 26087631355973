import React, { useEffect, useState } from 'react'
import { Table, message, Spin, Avatar, Select, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { dateFormatHandler } from '../../../functions'
import NamedUserModal from '../../Modals/namedUserModal'
import { useDispatch } from 'react-redux'
import { setNamedUserEmployerProfiles } from '../../../redux/actions/user'
import axios from 'axios'
import { strapiURL } from '../../../config'

const EmployerDashboardNamedUsers = ({ userProfile, loadUserProfile }) => {
  const [namedUsers, setNamedUsers] = useState([])
  const [backupData, setBackupData] = useState([])
  const [loading, setLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [sortValue, setSortValue] = useState('')
  // const [editModalOpen, setEditModalOpen] = useState(false)
  // const [currentNamedUser, setCurrentNamedUser] = useState({})

  const [tableLoading, setTableLoading] = useState({
    id: null,
    loading: false,
  })
  const { Option } = Select
  const dispatch = useDispatch()

  const handleDeleteUser = id => {
    setTableLoading({
      id: id,
      loading: true,
    })
    axios
      .delete(strapiURL + `/employers/${userProfile?.id}/namedUser/${id}`)
      .then(res => {
        message.success('User deleted')
        let _users = [...namedUsers]
        _users = _users.filter(item => item?.id !== id)
        dispatch(setNamedUserEmployerProfiles(_users))
        setNamedUsers(_users)
        loadUserProfile()
        setTableLoading({
          id: null,
          loading: false,
        })
      })
      .catch(err => {
        message.error('Something went wrong')
        setTableLoading({
          id: null,
          loading: false,
        })
      })
  }
  useEffect(() => {
    if (userProfile) {
      let _users = []
      if (userProfile?.named_users?.length) {
        userProfile?.named_users?.forEach(emp => {
          _users.push({
            id: emp?.id,
            name: emp?.name,
            avatar: emp?.image,
            phone: emp?.phone,
            email: emp?.user?.username,
            createdAt: emp?.user?.created_at,
            auth_id: emp?.user?.auth0_user_id,
            is_active: emp?.user?.is_active || false,
            last_login: emp?.user?.last_login || null,
            master_access: emp?.master_access || false,
          })
        })
        _users = _users.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        setNamedUsers(_users)
        setBackupData(_users)
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
  }, [userProfile])

  // const statusHandler = (username, id, value) => {
  //   setTableLoading({
  //     id: id,
  //     loading: true,
  //   })
  //   axios
  //     .post(strapiURL + `/named-users/status/update`, {
  //       username: username,
  //     })
  //     .then(res => {
  //       message.success('User updated')
  //       let _users = [...namedUsers]
  //       _users[_users.findIndex(item => item?.id === id)].is_active = value
  //       setNamedUsers(_users)
  //       setTableLoading({
  //         id: null,
  //         loading: false,
  //       })
  //     })
  //     .catch(err => {
  //       message.error('Something went wrong')
  //       setTableLoading({
  //         id: null,
  //         loading: false,
  //       })
  //     })
  // }

  const jobCols = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            {record?.avatar ? (
              <div
                className="h-8 w-8 bg-no-repeat bg-cover"
                style={{
                  backgroundImage: `url(${record.avatar})`,
                }}
              />
            ) : (
              <Avatar
                className="flex items-center justify-center"
                shape="square"
                icon={<UserOutlined />}
              />
            )}
            <p className="text-black font-sans font-semibold mb-0 text-last ml-2">
              {record.email}
            </p>
          </div>
        )
      },
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => {
        return (
          <div>
            {record.is_active ? (
              <p className="text-[#059913] font-semibold text-xs mb-0 font-sans">
                Active
              </p>
            ) : (
              <p className="text-[#C40000] font-semibold text-xs mb-0 font-sans">
                Deactivated
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: 'Last login',
      dataIndex: 'last_login',
      key: 'last_login',
      render: (text, record, index) => {
        return (
          <p className="inline-block bg-[#FEF8EA] text-[#756F63] text-location text-last font-sans mb-0 px-2 rounded">
            {record.last_login
              ? dateFormatHandler(record.last_login)
              : 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Added On',
      dataIndex: 'added_on',
      key: 'added_on',
      render: (text, record, index) => {
        return (
          <>
            <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-last font-sans mb-0 px-2 rounded">
              {record?.createdAt
                ? dateFormatHandler(record?.createdAt)
                : 'Not Specified'}
            </p>
          </>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <span
              onClick={() => handleDeleteUser(record.id)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Remove User
            </span>
            <div
              className={
                tableLoading.loading && tableLoading.id === record.id
                  ? 'opacity-100'
                  : 'opacity-0'
              }
            >
              <Spin className="action-loader ml-2" />
            </div>
          </div>
        )
      },
    },
  ]
  const sortHandler = val => {
    let _namedUser = [...namedUsers]
    if (!_namedUser.length) {
      message.info('No Named User found.')
      return
    }
    setSortValue(val)
    if (val === 'email') {
      _namedUser.sort((a, b) => a.email.localeCompare(b.email))
    }
    if (val === 'addedOn') {
      _namedUser.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }
    if (val === 'lastLogin') {
      _namedUser.sort((a, b) => new Date(b.last_login) - new Date(a.last_login))
    }

    setNamedUsers(_namedUser)
  }
  const searchHandler = value => {
    let filteredItems = backupData.filter(
      item => item.email.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
    setNamedUsers(filteredItems)
  }

  return (
    <div className="mt-6 lg:mt-0">
      <h1 className="font-playfair text-headingFive lg:text-headingThree text-merlot">
        Add/Change Named Users
      </h1>
      <div>
        <div className="w-full lg:w-[30%]">
          <Input
            onChange={event => searchHandler(event.target.value)}
            className="py-3 rounded-md"
            placeholder="Search Named User"
          />
        </div>
        <div className="rounded-lg bg-white shadow-profileCard mt-8">
          <div className="px-3 lg:px-4 py-4 border-b border-bgSecondaryButton sm:flex sm:justify-between">
            <div className="flex items-center">
              <img src="/icons/icon-application.svg" className="mb-0" />
              <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                Named Users &#62; Users
              </h1>
            </div>
            <div className="flex items-center justify-between lg:justify-end mt-5 lg:mt-0">
              <div className="relative border border-[#C4C4C4] rounded focus:border-merlot px-2 py-1 sort-select mr-4 sort-by-text">
                <span className="absolute z-10 font-sans font-semibold text-[#6A6A6A] mt-1">
                  Sort By:
                </span>
                <Select
                  defaultValue={sortValue}
                  onChange={val => {
                    sortHandler(val)
                  }}
                  className="bg-transparent z-20 relative"
                >
                  <Option value="email">Email</Option>
                  <Option value="addedOn">Added On</Option>
                  <Option value="lastLogin">Last login</Option>
                </Select>
              </div>
              <button
                onClick={() => setModalOpen(true)}
                className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2 mt-2 sm:mt-0"
              >
                Add User
              </button>
            </div>
          </div>
          {!loading ? (
            <Table
              className="hide-table-header-background hide-table-header-cols-border table-with-pagination"
              columns={jobCols}
              scroll={{ x: 'md:mx-content' }}
              dataSource={namedUsers}
              bordered={false}
            />
          ) : (
            <div
              className="min-h w-full min-h-700px bg-no-repeat bg-contain bg-center"
              style={{
                backgroundImage: `url(/loaders/named-user-loader.png)`,
              }}
            />
          )}
        </div>
      </div>
      {/* <UpdateNamedUserEmail
        setModalOpen={setEditModalOpen}
        modalOpen={editModalOpen}
        userProfile={userProfile}
        currentNamedUser={currentNamedUser}
        setCurrentNamedUser={setCurrentNamedUser}
      /> */}
      <NamedUserModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        userProfile={userProfile}
        loadUserProfile={loadUserProfile}
        user={namedUsers}
        setUsers={setNamedUsers}
      />
    </div>
  )
}
export default EmployerDashboardNamedUsers
