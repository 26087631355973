import React, { useState } from 'react'
import { Form, message, Modal, Input } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import axios from 'axios'
import { auth0Roles, strapiURL } from '../../config'

const VIEW_MODE_EMAIL = 'VIEW_MODE_EMAIL'
const VIEW_MODE_PASSWORD = 'VIEW_MODE_PASSWORD'

const NamedUserModal = ({
  modalOpen,
  userProfile,
  setModalOpen,
  loadUserProfile,
}) => {
  const [form] = Form.useForm()
  const [viewMode, setViewMode] = useState(VIEW_MODE_EMAIL)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [userInfor, setUserInfor] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })

  const closeModalHandler = () => {
    setLoading(false)
    setModalOpen(false)
    setErrorMsg('')
    setViewMode(VIEW_MODE_EMAIL)
    form.resetFields()
  }

  const handleSubmit = async values => {
    console.log('form value ---', values)
    if (viewMode === VIEW_MODE_EMAIL) {
      setUserInfor({
        email: (values.email || '').toLowerCase(),
        firstName: values.firstName,
        lastName: values.lastName,
      })

      try {
        let data = {
          employerProfileId: userProfile?.id,
          email: (values.email || '').toLowerCase(),
          roleId: auth0Roles?.NamedUser?.id,
        }
        const result = await axios.post(strapiURL + '/auth0/user/check', data)
        if (result?.data?.status === 'create') {
          setViewMode(VIEW_MODE_PASSWORD)
          return
        }
        if (result?.data.status === 'done') {
          message.success('User added', 8)
          loadUserProfile()
          closeModalHandler()
          setLoading(false)
          return
        }
        if (result?.data.status === 'error') {
          setErrorMsg(result.data.msg)
        }
      } catch (error) {
        console.log('error ---', error)
      }
    } else {
      if (values?.named_user_password !== values?.named_user_confirm_password) {
        message.error("Password doesn't match.", 8)

        return
      }
      setLoading(true)
      let data = {
        ...userInfor,
        employerProfileId: userProfile?.id,
        password: values?.named_user_password,
        roleId: auth0Roles?.NamedUser?.id,
      }
      axios
        .post(strapiURL + '/auth0/signup', data)
        .then(res => {
          message.success('User created', 8)
          loadUserProfile()
          closeModalHandler()
          setLoading(false)
        })
        .catch(err => {
          if (err.response) {
            message.error(err.response.data.message[0].error)
          } else {
            message.error('Something went wrong', 8)
          }
          setLoading(false)
        })
    }
  }

  return (
    <Modal
      width={700}
      footer={null}
      onCancel={closeModalHandler}
      visible={modalOpen}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="modal-head bg-voyage py-5 px-5 rounded-tl rounded-tr">
        <p className="font-sans font-bold text-base text-white mb-1">
          Add Named User
        </p>
        <p className="font-sans text-xs text-white mb-0">
          Named users have access to view and manage all jobs, regardless of
          poster, can access resume search if available, and can add and remove
          other named users.
        </p>
      </div>
      <div className="bg-white px-6 py-8 rounded-bl rounded-br">
        <div className="w-full">
          <Form
            layout="vertical"
            form={form}
            name="namedUserForm"
            onFinish={handleSubmit}
          >
            {viewMode === VIEW_MODE_EMAIL && (
              <>
                <p>{errorMsg}</p>
                <Form.Item
                  className="theme-form-item"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="First Name"
                    className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
                <Form.Item
                  className="theme-form-item"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Last Name"
                    className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
                <Form.Item
                  className="theme-form-item"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="E-Mail"
                    className="placeholder-charcoal focus:border-merlot focus:outline-none focus:shadow-none"
                  />
                </Form.Item>
              </>
            )}
            {viewMode === VIEW_MODE_PASSWORD && (
              <>
                <p>
                  There is no user associated with this email. Please input
                  password.
                </p>
                <Form.Item
                  className="theme-form-item-password"
                  name="named_user_password"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    iconRender={visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item
                  className="theme-form-item-password"
                  name="named_user_confirm_password"
                  rules={[
                    {
                      required: true,
                      message: 'Required.',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    iconRender={visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </>
            )}

            <div className="flex items-center mt-8">
              <button
                onClick={() => closeModalHandler()}
                type="button"
                className="bg-transparent focus:outline-none text-merlot"
              >
                Cancel
              </button>
              {viewMode === VIEW_MODE_EMAIL && (
                <button
                  type="submit"
                  className="bg-bgSecondaryButton py-3 px-6 text-voyage font-bold rounded text-xs font-sans ml-4"
                >
                  Add
                </button>
              )}

              {viewMode === VIEW_MODE_PASSWORD && (
                <button
                  type="submit"
                  className="bg-bgSecondaryButton py-3 px-6 text-voyage font-bold rounded text-xs font-sans ml-4"
                >
                  {loading ? 'Adding...' : 'Add'}
                </button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
export default NamedUserModal
