import React from 'react'
import Layout from '../../components/employerDashboard/layout'
import NamedUsers from '../../components/employerDashboard/namedUsers'
const EmployerNamedUsersPage = ({ location }) => {
  return (
    <Layout title="Named Users | MedReps.com" location={location}>
      <NamedUsers />
    </Layout>
  )
}
export default EmployerNamedUsersPage
